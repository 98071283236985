import {
    Box,
    Button,
    ButtonGroup, CloseButton,
    InlineNotification,
    Input,
    Modal,
    Textarea,
    useDisclosure,
    useToast
} from "@lego/klik-ui";
import {ComputerBugBold} from "@lego/klik-ui/icons";
import React, {useState} from "react";
import {Issue} from "../../models/Issue";
import {getToken} from "../../auth/authConfig";
import {ReportBug} from "../../services/ReportBug";
import {useMsal} from "@azure/msal-react";
import {AxiosResponse} from "axios";

export const BugReportModal = () => {
    const { instance } = useMsal();
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [processing, setProcessing] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const initialFocusRef = React.useRef(null);
    const finalFocusRef = React.useRef(null);
    const toast = useToast();
    
    const sendReport = async (title: string, body: string): Promise<boolean> => {
        const issue: Issue = {title: title, body: body};
        let responseCode = 500;
        if (issue.title !== "" || issue.body !== "") {
            const token = await getToken(instance);
            const gitService = new ReportBug();
            const response: AxiosResponse = await gitService.reportBug(token.accessToken!, issue);
            responseCode = response.status;
        }
        return responseCode === 200;
    }
    
    const reportBug =  async (title: string, body: string) => {
        setProcessing(true);
        const success = await sendReport(title, body);
        if (success) {
            onClose();
            setTitle("");
            setBody("");
            toast({
                position: 'bottom-right',
                duration: 10000,
                render: ({ onClose }) => (
                    <InlineNotification variant={"success"}>
                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                            <InlineNotification.Title>Success</InlineNotification.Title>
                            <InlineNotification.Description>
                                Issue has successfully been reported.
                            </InlineNotification.Description>
                        </InlineNotification.Content>
                        <CloseButton aria-label="Close" onClick={onClose} />
                    </InlineNotification>
                ),
            });
        }else{
            toast({
                position: 'bottom-right',
                duration: 10000,
                render: ({ onClose }) => (
                    <InlineNotification variant={"error"}>
                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                            <InlineNotification.Title>Failed</InlineNotification.Title>
                            <InlineNotification.Description>
                                Something went wrong when trying to report the issue.
                            </InlineNotification.Description>
                        </InlineNotification.Content>
                        <CloseButton aria-label="Close" onClick={onClose} />
                    </InlineNotification>
                ),
            });
        }
        
        setProcessing(false);
    };
    
    return (
          <Box textAlign="right">
        <Button
            className="report-button"
            backgroundColor={"warning.500"}
            onClick={onOpen}
            rightIcon={<ComputerBugBold />}
            sx={{
                '&:hover': {
                    backgroundColor: "warning.600"
                },
            }}
        >
            Report a Bug
        </Button>
        <Modal
            finalFocusRef={finalFocusRef}
            initialFocusRef={initialFocusRef}
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Modal.Overlay/>
            <Modal.Content>
                <Modal.Header>Report A Bug</Modal.Header>
                <Modal.CloseButton />
                <Modal.Body>
                    <Input
                        placeholder="Title"
                        ref={initialFocusRef}
                        value={title}
                        onChange={(e)=> setTitle(e.target.value)}
                        marginBottom={"10px"}/>
                    <Textarea
                        placeholder="Description"
                        value={body}
                        onChange={(e)=> setBody(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            isDisabled={title === "" || body === "" || processing}
                            onClick={() => reportBug(title, body)}>Send Report</Button>
                        <Button onClick={() => {
                            setTitle("");
                            setBody("");
                            onClose();
                        }
                        }
                                variant="outline">
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    </Box>
    );
}