import React, { useState, useEffect } from 'react';
import { getToken } from "../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { Device } from "../models/Device";
import { Devices } from "../services/Devices";
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    Popover,
    Search,
    Spinner, Tab,
    Table,
    Text
} from "@lego/klik-ui";
import {DeviceRow} from "../components/rows/DeviceRow";
import {DeviceHeader} from "../components/rows/DeviceHeader";

/**
 * Props for the DeviceList component.
 *
 * @interface DeviceListProps
 * @property {string[]} selectedDevice - the list of selected devices ids.
 * @property {function} handleSelectedDevices - function to handle when device is selected or deselected.
 * @property {function} resetDeviceSelection - function to reset the device selection.
 */
interface DeviceListProps {
    selectedDevice: Device[];
    handleSelectedDevices: (event: React.ChangeEvent<HTMLInputElement>, newSelectedDevice: Device) => void;
    resetDeviceSelection: () => void;
}

/**
 * DeviceList component renders a table of factory devices and displays ones that are selected separately.
 *
 * @param {DeviceListProps} - The props for the DeviceList component.
 * @returns {JSX.Element} - The rendered DeviceList component.
 */
export const DeviceList: React.FC<DeviceListProps> = ({ selectedDevice, handleSelectedDevices, resetDeviceSelection }: DeviceListProps): JSX.Element => {

    const { instance } = useMsal();

    const [devices, setDevices] = useState<Device[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [searchDevice, setSearchDevice] = useState<string>("");
    const [minHeight, setMinHeight] = useState('100vh');
    const [devicesAreLoaded, setDevicesAreLoaded] = useState<boolean>(false);
    const [openPopover, setOpenPopover] = useState<boolean>(false);

    let filteredDevices = devices ? devices.filter(device =>
        device.displayName.toLowerCase().includes(searchDevice.toLowerCase())
    ) : [];


    const handleClearSelection = () => {
        resetDeviceSelection();
        setOpenPopover(true);
        setTimeout(() => {setOpenPopover(false)}, 3000);
    };
    
    /**
     * Sets the minimum height of the component based on the height of the navbar element.
     */
    useEffect(() => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            setMinHeight(`calc(100vh - ${navbar.offsetHeight}px)`);
        }
    }, []);

    /**
     * Loads devices from the API when the component is mounted and devices are not yet loaded.
     */
    useEffect(() => {
        if (!devicesAreLoaded){
            setIsLoading(true);
            getToken(instance).then(token => {
                const appService = new Devices();
                appService.getDevices(token.accessToken!, searchDevice).then((response: { data: Device[], odataLink: string }) => {
                    setDevices(response.data);
                    setIsLoading(false);
                    setDevicesAreLoaded(true);
                });
            });
        }

    }, [instance, searchDevice, devicesAreLoaded]);


    return (
        <Flex className="devices-column" style={{ minHeight: minHeight }} flexDirection="column" bg="light-blue.600" p={3}>
            <Grid alignItems="center" templateColumns="repeat(2, 1fr)" mb={3}>
                <Box className="devices-header" mb={3}>
                    <Text as="h2" textStyle="2xl" color="white">Device List</Text>
                </Box>
            </Grid>

            <Flex className="devices-container" direction="column">
                <Box className="device-search" mb={4}>
                    <Search
                        onChange={(value: string) => {
                            setSearchDevice(value);
                        }}

                        onEnterPressed={(value: string) => {
                            setSearchDevice(value);
                        }}

                        debounceMs={1000}
                        value={searchDevice}
                        size="md"
                        placeholder="Search for a device" />
                </Box>
                <Flex flexDirection="column" mb={3}>

                    <Grid alignItems="center" templateColumns="repeat(2, 1fr)">
                        <Box>
                            <Text as="h4" textStyle="lg" color="white">
                                {selectedDevice.length} {selectedDevice.length === 1 ? 'device' : 'devices'} selected.
                            </Text>
                        </Box>

                        <Box className="devices-reset-selection" textAlign="right">
                            <Popover isOpen={openPopover} onClose={() => setOpenPopover(false)} placement={"right-start"}>
                                <Popover.Trigger>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        color="white"
                                        borderColor="white"
                                        borderRadius="0"
                                        isDisabled={selectedDevice.length <= 0}
                                        onClick={handleClearSelection}
                                    >
                                        Clear Selection
                                    </Button>
                                </Popover.Trigger>
                                <Popover.Content alignItems={"flex-start"}>
                                    <Popover.Arrow />
                                    <Popover.CloseButton />
                                    <Popover.Header>Device Selection</Popover.Header>
                                    <Popover.Body>Device selection has been reset.</Popover.Body>
                                </Popover.Content>
                            </Popover>
                        </Box>
                    </Grid>
                </Flex>
            </Flex>
            <Flex className="devices-container" direction="column" bg="white">
                {isLoading ? (
                    <Box p={4}>
                        <Spinner color="light-blue.600" mx="auto" />
                        <Text as="h4" textStyle="xl" color="light-blue.600" fontWeight="bold" textAlign="center" my={2}>Loading device list...</Text>
                    </Box>
                ) : (
                    <Box className="device-list-container">
                        {selectedDevice.length > 0 && (
                            <Table bg="white" borderBottomWidth={10} borderBottomColor={"light-blue.600"}>
                                <DeviceHeader header={selectedDevice.length === 1 ? 'Selected Device' : 'Selected Devices'}/>
                                {selectedDevice.map((device) => (
                                    <DeviceRow key={device.id} device={device} handleSelectedDevices={handleSelectedDevices} selected={true}/>
                                ))}
                            </Table>
                        )}
                        {selectedDevice.length > 0 && (
                            <Divider/>
                        )}
                        <Table>
                            <DeviceHeader header={"Available Devices"}/>
                        </Table>
                        <div className="scroll-container">
                            <Table bg="white">
                                {filteredDevices.length > 0 ? (
                                    filteredDevices.slice(0, 100)
                                        .filter(device => !selectedDevice.some(selected => selected.id === device.id))
                                        .sort((a, b) => a.displayName.localeCompare(b.displayName))
                                        .map((device) => (
                                            <DeviceRow key={device.id} device={device} handleSelectedDevices={handleSelectedDevices} selected={false}/>
                                        ))
                                ) : (
                                    <Text bg="error.400" color="white" fontWeight="bold" textAlign="center" p={3}>No results
                                        found.</Text>
                                )}
                            </Table>
                        </div>

                    </Box>
                )}
            </Flex>
        </Flex>
    );
};