import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Link as RouterLink } from "react-router-dom";
import { getRoles, getToken } from "../auth/authConfig";
import {
  Container,
  Navbar,
  LEGOLogo,
  Spacer,
  Text,
  Box
} from "@lego/klik-ui";
import {BugReportModal} from "./modals/BugReportModal";
import {FeedbackModal} from "./modals/FeedbackModal";


export const Menu = () => {
  const { instance } = useMsal();
  const [roles, setRoles] = useState<string[] | undefined>(undefined);
  
  
  
  useEffect(() => {
    getToken(instance).then(token => {
          setRoles(getRoles(token.accessToken));
        })
  }, [instance]);

  return (
    <Container id="navbar" className="navbar" maxWidth="100%" padding={0}>
      <Navbar bg="light-blue.800" height="auto" py="4">
        <Navbar.Brand as={RouterLink} to="/" alignItems="flex-end">
          <LEGOLogo mr="24px" />
          <Box display="flex" flexDirection="column">
            <Text as="i" fontSize="4xl" fontWeight="extrabold" textTransform="uppercase">SODA</Text>
            <Text as="i" fontSize="sm" fontWeight="bold" textTransform="uppercase" opacity="0.7">Software Device Assignment</Text>
          </Box>
        </Navbar.Brand>
        <Spacer />
        <BugReportModal></BugReportModal>
        <Box width="15px"></Box>
        <FeedbackModal></FeedbackModal>
        <Navbar.Burger display={{ base: "block", lg: "none" }} />
      </Navbar>
    </Container>
  );
}