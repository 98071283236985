import {Table} from "@lego/klik-ui";
import React from "react";


/**
 * Props for the DeviceHeader component
 * 
 * @interface DeviceHeaderProps
 * @property {string} header - The header of the device table.
 */
interface DeviceHeaderProps{
    header: string
}

/**
 * DeviceHeader component renders the header of the device table section.
 * 
 * @param {DeviceHeaderProps} - The props for the DeviceHeader component.
 * @returns {JSX.Element} - The rendered DeviceHeader component.
 */
export const DeviceHeader = ({header}: DeviceHeaderProps): JSX.Element => {
  return(
      <Table.Head fontWeight={"bold"} borderBottomWidth={1} borderColor={"light-blue.600"}>
          <Table.Row>
              <Table.Cell w={"70%"} h={"100%"}>
                  {header}
              </Table.Cell>
              <Table.Cell w={"30%"} textAlign={"right"}>
                  See Details
              </Table.Cell>
          </Table.Row>
      </Table.Head>
  )  
};