import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getRoles, getToken } from "./auth/authConfig";
import { Grid} from "@lego/klik-ui";
import { Menu } from "./components/Menu";
import { DeviceList } from "./pages/DeviceList";
import { GroupList } from "./pages/GroupList";
import {CombinedGroup} from "./models/CombinedGroup";
import {Device} from "./models/Device";

function App() {
    const { instance } = useMsal();
    const [roles, setRoles] = useState<string[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<Device[]>([]);

    const handleSelectedDevice = (event: React.ChangeEvent<HTMLInputElement>, device: Device) => {
        if (event.target.checked){
            setSelectedDevice(prev => [...prev, device]);
            console.log(selectedDevice);
        }
        else {
            setSelectedDevice(prev => prev.filter(selected => selected.id !== device.id));
            console.log(selectedDevice);
        }
    };


    const resetDeviceSelection = () => {
        setSelectedDevice([]);
    };

    useEffect(() => {
        getToken(instance).then(token => {
            setRoles(getRoles(token.accessToken));
        });
    }, [instance]);

    return (
        <>
            <Menu />
            <Grid className="main-content" templateColumns="1fr 3fr">
                <DeviceList selectedDevice={selectedDevice} handleSelectedDevices={handleSelectedDevice}
                            resetDeviceSelection={resetDeviceSelection}/>
                <GroupList selectedDevice={selectedDevice} />
            </Grid>
        </>
    );
}

export default App;